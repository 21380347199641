/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { InputFieldContainer, CustomTextAreaField } from '../SocialFeeds/styles';
import { Square, AddQuizDashboard, OptionCircle, Flex, QuizContainer, OptionContainer, Layout } from '../ArticleQuiz/styles';
import { StyledInput } from '../../containers/Settings/styles';
import { SaveButton } from '../CreateEvent/styles';
import { Title, AdventureTitle, TitleContainer, } from '../Article/styles';
import { ArticleButtonsContainer, BackButton } from '../Journeys/styles';
import { EditQuiz, FetchQuizzes } from '../../redux/actions';
import { NewPlusIcon, closeIcon } from '../../utils/icons';

class EditQuizQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonDisable: false,
      quiz: '',
      storeQuizId: '',
      choices: [
        {
          choice: '',
          is_correct: 0
        }
      ],
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.buttonDisableFunc();
    const { adventureArticle, history, quizQuestions, fetchQuizzes } = this.props;
    const arrayOfPath = history.location.pathname.split('/');
    const quizId = arrayOfPath[arrayOfPath.length - 1]

    if (!adventureArticle) {
      history.push(`/education/adventure/${arrayOfPath[arrayOfPath.length - 3]}/article/${arrayOfPath[arrayOfPath.length - 1]}`)
    }
    else {
      fetchQuizzes(adventureArticle.id, 0);
    }
    quizQuestions && quizQuestions.map((item) => {
      if (quizId == item.id) {
        item.choices.map((option) => {
          delete option['quiz_id'];
          option['is_correct'] = 0
        })
        this.setState({ quiz: item['quiz'], choices: item.choices, storeQuizId: item['id'] })
      }
    })
  }

  enterQuestion = (e) => {
    if (this.state.quiz.length < e.target.value.length) {
      if (e.target.value.trim() !== '') {
        this.setState({ quiz: e.target.value }, () => this.buttonDisableFunc());
      }
    }
    else {
      this.setState({ quiz: e.target.value }, () => this.buttonDisableFunc());
    }
  };

  buttonDisableFunc = () => {
    let dupChoice = [...this.state.choices];
    let prevIndex = dupChoice.findIndex((choice) => choice.is_correct === 1);
    let emptyOption = dupChoice.findIndex((list) => list.choice.trim() === '');
    let emptyQustion = this.state.quiz.trim() === '';

    if (prevIndex > -1 && emptyOption == -1 && !emptyQustion && dupChoice.length >= 2) {
      this.setState({ buttonDisable: false });
    }
    else {
      this.setState({ buttonDisable: true });
    }
  };

  resetState = () => {
    const { history, adventureArticle } = this.props;
    history.push(`/education/adventure/${adventureArticle.category_name}/article/${adventureArticle.id}/show-all-quiz`);
  };

  submit = () => {
    const { editQuiz, adventureArticle } = this.props;
    const { quiz, choices, storeQuizId } = this.state;
    let data = {};
    data['quiz'] = quiz;
    data['quiz_id'] = storeQuizId;
    data['choices'] = [...choices];
    editQuiz(data, adventureArticle.id, this.resetState);
    this.setState({
      buttonDisable: false,
    });
  };

  addChoice = () => {
    let dupChoice = [...this.state.choices];
    let obj = {
      id: '',
      choice: '',
      is_correct: 0
    };
    if (dupChoice.length < 4) {
      dupChoice.push(obj);
    }
    this.setState({ choices: dupChoice }, () => this.buttonDisableFunc());
  }

  enterChoice = (e, index, id) => {
    let dupChoice = [...this.state.choices];
    if (dupChoice[index].choice.length < e.target.value.length) {
      if (e.target.value.trim() !== '') {
        dupChoice[index].choice = e.target.value;
        dupChoice[index].id = id;
        this.setState({ choices: dupChoice }, () => this.buttonDisableFunc());
      }
    }
    else {
      dupChoice[index].choice = e.target.value;
      this.setState({ choices: dupChoice }, () => this.buttonDisableFunc());
    }
  }

  deleteOption = (index) => {
    let dupChoice = [...this.state.choices];
    if (dupChoice.length > 1) {
      dupChoice.splice(index, 1);
    }
    this.setState({ choices: dupChoice }, () => this.buttonDisableFunc());
  }

  enterCorrectCheckmark = (index) => {
    let dupChoice = [...this.state.choices];
    if(dupChoice[index].is_correct === 0){
      dupChoice[index].is_correct = 1
    }
    else{
      dupChoice[index].is_correct = 0
    }
    this.setState({ choices: dupChoice }, () => this.buttonDisableFunc());
  };

  optionChoice = (index) => {
    switch (index) {
    case 0:
      return `A`;
    case 1:
      return `B`;
    case 2:
      return `C`;
    case 3:
      return `D`;
    }
  }

  tab = (e) => {
    if (e.key == 'Tab') {
      if (this.state.choices.length < 4) {
        this.addChoice();
      }
      else {
        if (!this.state.buttonDisable) {
          this.submit();
        }
      }
    }
  };

  showChoice = (choices) => (
    choices.map((option, index) => (
      <Flex key={index} noWidth="100%" noCenter={true}>
        <Square isChecked={option.is_correct} onClick={() => this.enterCorrectCheckmark(index)}>{this.optionChoice(index)}</Square>
        <OptionContainer>
          <StyledInput
            value={option.choice}
            placeholder="Enter Option"
            onChange={(e) => this.enterChoice(e, index, option.id)}
            onKeyDown={(e) => this.tab(e)}
          />
        </OptionContainer>
        {choices.length > 1 && option.id === null ?
          <OptionCircle marginTop="16px" marginLeft="20px">
            <div className="circle" onClick={() => this.deleteOption(index)}>
              <div style={{width:"16px",height:"16px", display:"flex",alignItems:"center"}}>{closeIcon("white")}</div>
              {/* <span className="glyphicon glyphicon-remove" /> */}
            </div>
          </OptionCircle> : null
        }
      </Flex>))
  );

  render() {
    const { quiz, choices } = this.state;
    const { adventureArticle } = this.props;
    let choicesLength;
    let choiceLength;
    choiceLength = 0;
    let choiceDisable;
    choiceDisable = 0;
    choicesLength = choices.length;
    if (choicesLength > choiceLength) {
      for (let i = 0; i < choicesLength; i++) {
        if (choices[i].choice.length > 0) {
          choiceDisable = i + 1;
        }
      }
    }

    return (
      <AddQuizDashboard>
        <Layout>
          <TitleContainer>
            <Title mainTitle>{adventureArticle && adventureArticle.category_name}</Title>
            <AdventureTitle>{adventureArticle && adventureArticle.title}</AdventureTitle>
          </TitleContainer>
          <ArticleButtonsContainer right>
            <BackButton onClick={() => this.resetState()}>
              <img src="/public/images/educationAdventureIcons/back-arrow.png" alt="back-arrow" />
              Back
            </BackButton>
          </ArticleButtonsContainer>
        </Layout>
        <Layout>
          <Flex marginBottom="15px" fontSize="16px" marginTop="15px" noCenter={1}>
            <div className="header">
              Question
            </div>
          </Flex>
          <InputFieldContainer>
            <CustomTextAreaField
              placeholder="Enter Quiz Question"
              rows="3"
              name="description"
              data-gramm_editor="false"
              onChange={(e) => this.enterQuestion(e)}
              value={quiz}
            />
          </InputFieldContainer>
          <QuizContainer>
            <Flex marginTop="15px" fontSize="16px" noCenter={1}>
              <div className="header">
                Options
              </div>
            </Flex>
          </QuizContainer>
          <div>
            {this.showChoice(choices)}
          </div>
          {choices.length < 4 &&
            <Flex noCenter={1}>
              <OptionCircle marginTop="16px" bgColor="#93bf3d" onClick={() => this.addChoice()}>
                <div className="circle">
                  {/* <span className="glyphicon glyphicon-plus" /> */}
                  <NewPlusIcon fill={"white"}/>
                </div>
              </OptionCircle>
              <div className="addMore" onClick={() => this.addChoice()}> Add more option</div>
            </Flex>
          }
          <Flex marginTop="15px" marginBottom="15px" noCenter={1}>
            {(quiz.length > 0 && (choiceDisable == choices.length)) ?
              <SaveButton onClick={this.submit}>
                Submit
              </SaveButton> :
              <SaveButton disabled={true} onClick={this.submit}>
                Submit
              </SaveButton>}
          </Flex>
        </Layout>
      </AddQuizDashboard>
    )
  }
}

EditQuizQuestion.propTypes = {
  editQuiz: PropTypes.func.isRequired,
  fetchQuizzes: PropTypes.func,
  adventureArticle: PropTypes.object,
  quizQuestions: PropTypes.array,
  history: PropTypes.object
};

const mapStateToProps = (state) => ({
  adventureArticle: state.education.journeyDetails,
  quizQuestions: state.education.quizQuestions
});

const mapDispatchToProps = (dispatch) => ({
  editQuiz: (data, journeyId, cb) => dispatch(EditQuiz(data, journeyId, cb)),
  fetchQuizzes: (id, value) => dispatch(FetchQuizzes(id, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditQuizQuestion);